import React, { } from "react";
import { Table, Button, Loader, Icon } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";

import { useToggleModalNewEdit } from "../../../hooks/useToggleModalNewEdit";


const defaultInitialFormValues = {
  country: "",
  cycles: "Once in two weeks",
  daysToDue: 14,
  vat_apply: true,
  vat: 0,
};

function BillingTable({
  bclientId,
  // profileId,
  billing = [],
  isLoading,
  syslang,lang
}) {
  const {
    isModalOpen,
    // isEditModeOn,
    // initFormValues,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaultInitialFormValues);

  //   useEffect(() => getCourierQuotations(bclientId), [bclientId]);

  //   const [isViewOpen, setViewOpen] = useState([]);
  console.log({ bclientId });

  if (isLoading)
    return (
      <div style={{ position: "relative", padding: "4em" }}>
        <Loader active size="small" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.country[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.period[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.daysdue[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.bills.viewbill.itemstable.vatrate[syslang]}</Table.HeaderCell>
      {/* <Table.HeaderCell>Apply VAT</Table.HeaderCell> */}
      {/* <Table.HeaderCell collapsing>Action</Table.HeaderCell> */}
    </Table.Row>
  );

  const tableBody = billing.map(
    ({ _id, country, cycles, daysToDue, vat_apply, vat }, i) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">{i + 1}</Table.Cell>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{cycles}</Table.Cell>
        <Table.Cell>{daysToDue}</Table.Cell>
        <Table.Cell>{vat}</Table.Cell>
        {/* <Table.Cell>
          {vat_apply && <Icon name="check" color="olive" />}
        </Table.Cell> */}
        {/* <Table.Cell>
          <Button
            compact
            basic
            color="red"
            icon="trash"
            size="mini"
            onClick={() =>
              deleteBilling({
                clientId: bclientId,
                profileId,
                billingId: _id,
              })
            }
          />
        </Table.Cell> */}
      </Table.Row>
    )
  );

  return (
    <>
      {!billing.length ? (
        <div className="center--text" style={{ padding: "2em" }}>
          <p>No billing options saved yet.</p>
          
        </div>
      ) : (
        <div style={{ padding: "1em" }}>
          {/* <Button
            compact
            floated="right"
            style={{ marginBottom: "1em" }}
            content="Add new"
            onClick={() => openModal(false)}
          /> */}
          <Table basic="very">
            <Table.Header>{tableHeader}</Table.Header>
            <Table.Body>{tableBody}</Table.Body>
          </Table>
        </div>
      )}

      <Modal
        open={isModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{ modal: "" }}
      >
        
      </Modal>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  //   courierQuotations: reduxState.businessclients.courierQuotations,
  isLoading: reduxState.businessclients.courierQLoading,
});

export default connect(mapStateToProps, {

})(BillingTable);
