import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import ExportExcel from "../../ExportExcel";



function ExportButton({data}) {


  const [dataSet, setData] = useState([{businessclient:'Na',createdAt:'na',shipTo:'na',shipFrom:{name:'na'},item:'na',qunatity:'na'}]);

  const colNames = [
    { label: "id",       value: "id" },
    { label: "businessclient",      value: "businessclient" },
    { label: "country",  value: "country" },
    { label: "city",  value: "city" },
    { label: "street",  value: "street" },
    { label: "clientName",  value: "clientName" },
    { label: "phone",  value: "phone" },
    { label: "COD",  value: "COD" },
    { label: "item",  value: "item" },
    { label: "sku",  value: "sku" },
    { label: "quantity",  value: "quantity" },
    { label: "price",  value: "price" },
    
    { label: "createdAt",  value: "createdAt" },

  ];

  useEffect(() => {
  
    let tempDataSet = [];
    
    for (let i=0;i<data.items.length;i++){
      const line = data.items[i]
      
      tempDataSet.push(
                  
        {
                id:data.clientOrderNr,
                businessclient:  data.businessclient.name,
                country:          `${data.shipping.shipTo.country}`,
                city:          `${data.shipping.shipTo.city}`,
                postcode:          `${data.shipping.shipTo.postcode}`,
                street:          `${data.shipping.shipTo.street} ${data.shipping.shipTo.streetNr}`,
                clientName:          `${data.shipping.shipTo.client.firstName} ${data.shipping.shipTo.client.lastName}`,
                phone:          `${data.shipping.shipTo.phone}`,
                COD:             data.payment.COD.amount,


                item:             line.name,
                sku:              line.sku,
                quantity:         line.quantity,
                price:         line.price?line.price:0,
                createdAt:        data.createdAt,
                

      })
    }

    setData(tempDataSet);
  }, [data]);

  return (
    <ExportExcel
      filename={`order_${data.clientOrderNr}`}
      excelSheetName="order"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button size='small' basic color="grey" >
          <Icon name="excel file" />
          xls
        </Button>
      }
    />
  );
}

export default (ExportButton);
