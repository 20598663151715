import React, { Component } from "react";
import {
  Button,
  Message,
  Label,
  Segment,
  Divider,
  Loader,
  Dimmer,
  // Header
} from "semantic-ui-react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import Services from "./Services";

import {
  addUpdates,
  cancelUpdates,
  updateOrder,
  getOrder,
  removeSuccessMsg,
  addOrderProduct,
  deleteOrderProduct,
  
  pauseOrder
} from "../../../actions/orders";
import Orderinstructions from './Orderinstructions'
import { orderAction } from "../../../actions/packing";
import { getCouriersByCountry } from "../../../actions/couriers";
// import { socket } from "../../../containers/Main";
import Notification from "../../Notification";
import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
import ClientInfo from "./ClientInfo";
import CancelOrderModal from "./CancelOrderModal";
import ProductsList from "../ProductsList_new/ProductsList";
import AddProduct from "../ProductsList/AddProduct";
import Newrefund from './addrefund'
import lang from '../../../lang/config.json'


const sh = "shipping.shipTo.";

class ViewOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdressAsShipping: false,
      issueInvoice: true,
      isModalOpen: false,
      hasChanges:false,
      showAddRefund:false

    };
    this.handleUpdateOrder = this.handleUpdateOrder.bind(this);
    // this.handleSetDefaultValues = this.handleSetDefaultValues.bind(this);
    this.handleContentUpdate = this.handleContentUpdate.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleAdressCheck = this.handleAdressCheck.bind(this);
    this.handleOrderAction = this.handleOrderAction.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
    this.handleAddInstruction    = this.handleAddInstruction.bind(this)
    this.handleRemoveInstruction = this.handleRemoveInstruction.bind(this)
  }

  handleAddInstruction(instruction){
    this.props.updateOrder({'processing.instructions':instruction},this.props.match.params.id)
  }
  
    
    handleRemoveInstruction(){
      this.props.updateOrder({'processing.instructions':''},this.props.match.params.id)}

  handleContentUpdate(name, text) {
    this.setState({...this.state,hasChanges:true})
    const adressFields = [
      "country",
      "province",
      "city",
      "street",
      "streetNr",
      "postcode"
    ];
    this.props.removeSuccessMsg();
    const adressField = adressFields.find(
      f => name === `shipping.shipTo.client.${f}`
    );
    const shippingAdressField = adressFields.find(
      f => name === `shipping.shipTo.${f}`
    );

    if (this.state.isAdressAsShipping) {
      if (adressField) {
        this.setState({ isAdressAsShipping: false });
        this.props.addUpdates({ [name]: text });
      } else if (shippingAdressField) {
        this.props.addUpdates({
          [name]: text,
          [`shipping.shipTo.client.${shippingAdressField}`]: text
        });
      }
    } else {
      
      this.props.addUpdates({ [name]: text });
    }
  }

  handleCheck(e, { name, value, checked }) {
    this.props.removeSuccessMsg();
    if (name === "issueInvoice") {
      this.setState({ [name]: checked });
    } else if (name === sh + "client.isCompany" && !checked) {
      this.props.addUpdates({
        [sh + "client.isCompany"]: checked,
        [sh + "client.companyName"]: "",
        [sh + "client.taxId"]: "",
        [sh + "client.phone"]: "",
        [sh + "client.country"]: "",
        [sh + "client.province"]: "",
        [sh + "client.city"]: "",
        [sh + "client.street"]: "",
        [sh + "client.postcode"]: "",
        [sh + "client.streetNr"]: ""
      });
    } else {
      this.props.addUpdates({ [name]: checked });
    }
  }

  handleAdressCheck(e, { name, value }) {
    this.props.removeSuccessMsg();

    this.setState(s => ({
      isAdressAsShipping: !value
    }));

    if (!value) {
      this.props.addUpdates({
        [sh + "client.country"]:
          this.props.orderUpdates[sh + "country"] ||
          this.props.order.shipping.shipTo.country,
        [sh + "client.province"]:
          this.props.orderUpdates[sh + "province"] ||
          this.props.order.shipping.shipTo.province,
        [sh + "client.city"]:
          this.props.orderUpdates[sh + "city"] ||
          this.props.order.shipping.shipTo.city,
        [sh + "client.postcode"]:
          this.props.orderUpdates[sh + "postcode"] ||
          this.props.order.shipping.shipTo.postcode,
        [sh + "client.street"]:
          this.props.orderUpdates[sh + "street"] ||
          this.props.order.shipping.shipTo.street,
        [sh + "client.streetNr"]:
          this.props.orderUpdates[sh + "streetNr"] ||
          this.props.order.shipping.shipTo.streetNr
      });
    }
  }

  handleOrderAction(action) {
    this.props.orderAction({
      action,
      orderId: this.props.match.params.id
    });
  }

  handleUpdateOrder() {
    this.props.updateOrder(this.props.orderUpdates, this.props.match.params.id);
    this.props.cancelUpdates();
  }

  handleToggleModal() {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
  }

  //Things to do before unloading/closing the tab
  doSomethingBeforeUnload = () => {
    // Do something
    // socket.emit("orderExit", {
    //   _id: this.props.order._id,
    //   username: this.props.username
    // });
  };

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", ev => {
      ev.preventDefault();
      return this.doSomethingBeforeUnload();
    });
  };

  componentDidMount() {
    this.props.getOrder(this.props.match.params.id);

    // Activate the event listener
    this.setupBeforeUnloadListener();
    // this.props.socket.on("closedOrder", (order) => {
    //   // console.log('server message: closedOrder ', order)
    //   alert(`closed order:${order}`)
    //   this.props.history.push(`/orders`)
    // });
    // this.props.socket.emit("orderId", this.props.match.params.id);
  }
  componentDidUpdate(prevProps) {
    const country = this.props.orderUpdates["shipping.shipTo.country"];
    if (!prevProps.order.shipping && this.props.order.shipping) {
      this.props.getCouriersByCountry(this.props.order.shipping.shipTo.country);
    }
    if (country !== prevProps.orderUpdates["shipping.shipTo.country"]) {
      this.props.getCouriersByCountry(country);
    }
  }
  componentWillUnmount() {
    this.doSomethingBeforeUnload();
    // this.handleOrderAction('close')
    this.props.cancelUpdates();
  }

  render() {
    const {
      user,
      order = { items: [] },
      orderUpdates,
      successMsg,
      isLoading,
      couriersList,
      addOrderProduct,
      deleteOrderProduct,
      removeSuccessMsg
    } = this.props;
    // console.log("STATE", this.state);

    if (isLoading)
      return (
        <Dimmer active inverted>
          <Loader inverted size="medium">
            Loading
          </Loader>
        </Dimmer>
      );

    if (Object.keys(order).length) {
      return (
        <>
        
          <Responsive
            mobileComponent={<InfoBar header={lang.orders.createOrder.backButton[user.currentUser.sys_lang]} linkBack="/orders" />}
          />
        <div className='order_view'>
        
           <div style={{marginLeft:"20px",marginTop:"10px",width:"800px"}}>
            <Notification
              type={"success"}
              trigger={successMsg}
              removeNotification={removeSuccessMsg}
            />
            {order.errMsgs &&
              order.errMsgs.map((err, i) => (
                <div className="alert alert-danger" key={i}>
                  {err}
                </div>
              ))}

            {/* Header */}
            <Responsive
              desktopComponent={
                <Head linkBack="/orders">
                  <div>
                    <Button onClick={()=>this.setState({showAddRefund:true})}>{lang.orders.viewOrder.refundbutton[user.currentUser.sys_lang]}</Button>

                    {['Client editing','Draft'].includes(order.status) && <Button color="green" onClick={()=>{    this.props.updateOrder({status:"New"}, this.props.match.params.id);
}}>
                    {lang.orders.viewOrder.validatebutton[user.currentUser.sys_lang]}
                    </Button>}
                    {['Order Ready for picking','Product Picking'].includes(order.status) &&<Button color="yellow" onClick={() => this.props.pauseOrder(order._id)}>
                    {lang.orders.viewOrder.draftbutton[user.currentUser.sys_lang]}
                    </Button>}
                    {['Order Ready for picking', 'Product Picking','Draft','Client editing','No goods','New'].includes(order.status) && <Button color="red" onClick={this.handleToggleModal}>
                        {lang.orders.viewOrder.cancelbutton[user.currentUser.sys_lang]}
                        </Button>
                        }
                    <CancelOrderModal
                      isModalOpen={this.state.isModalOpen}
                      handleCloseModal={this.handleToggleModal}
                      _id={order._id}
                    />


                    {/* <Button color="teal" onClick={this.handleUpdateOrder}>
                      Save updates
                    </Button> */}
                  </div>
                </Head>
              }
            />
          
          {!order.processing.instructions&&<Orderinstructions lang={lang} user={user}handleAddInstruction={this.handleAddInstruction} />}

          {order.processing.instructions && 

              <Message warning size='big'>
              <Message.Header>{lang.orders.viewOrder.addinstructionsbutton[user.currentUser.sys_lang]}</Message.Header>
              <p>{order.processing.instructions}</p>
              <Label  floating>
                    <button onClick={this.handleRemoveInstruction}>x</button >
                  </Label>
              </Message>
                  
                 
                 }
            {/* Form */}
            <ClientInfo
              lang={lang}
              user={user}
              orderUpdates={orderUpdates}
              order={order}
              id={this.props.match.params.id}
              issueInvoice={this.state.issueInvoice}
              isAdressAsShipping={this.state.isAdressAsShipping}
              handleContentUpdate={this.handleContentUpdate}
              handleCheck={this.handleCheck}
              handleAdressCheck={this.handleAdressCheck}
              couriersList={couriersList}
              
            />

            {/* Buttons */}
            {this.state.hasChanges && !['Canceled'].includes(order.status)&&<div className="flex--right">
              
                  <Button size="large" onClick={() => this.props.cancelUpdates()}>
                        {lang.orders.viewOrder.cancelbutton[user.currentUser.sys_lang]}
                    </Button>
                    
                    <Button
                      color="teal"
                      size="large"
                      onClick={this.handleUpdateOrder}
                    >
                     {lang.orders.viewOrder.savechange[user.currentUser.sys_lang]}
                    </Button>
                
            </div>}

            {/* ADD Products Section */}
            <section className="products">
              <Divider className="margin-bottom" horizontal>
                 {lang.orders.createOrder.addproductdivider[user.currentUser.sys_lang]}
              </Divider>
              <Segment className="padding-top margin-bottom">
                <AddProduct
                  lang={lang}
                  user={user}
                  handleAddProduct={item => addOrderProduct(order._id, item)}
                />
              </Segment>
              {order.items.length > 0 && (
                <Segment>
                  <ProductsList
                    lang={lang}
                    user={user}
                    onlyQuantity={true}
                    items={order.items}
                    handleDelete={itemId =>
                      deleteOrderProduct({orderId:this.props.order._id, itemId})
                    }
                  />
                </Segment>
              )}
            </section>

        
          </div>
          


          <div className='order_right'>
              <Segment>
               
                <Services
                  services={order.services}
                ></Services>
              </Segment>
              
            </div>
            <Newrefund 
                order ={order}
                show={this.state.showAddRefund} 
                closeModal={()=>this.setState({showAddRefund:false})}></Newrefund>       
          </div>

                
        </>
      );
    } else {
      return "Loading...";
    }
  }
}

const mapStateToProps = (reduxState, ownProps) => ({
  user:reduxState.user,
  order: reduxState.orders.currentOrder,
  orderUpdates: reduxState.orders.orderUpdates,
  successMsg: reduxState.orders.successMsg,
  isLoading: reduxState.orders.isLoading,
  username: reduxState.user.currentUser.username,
  couriersList: reduxState.couriers.listByCountry

});

export default connect(mapStateToProps, {
  addUpdates,
  cancelUpdates,
  updateOrder,
  getOrder,
  removeSuccessMsg,
  addOrderProduct,
  deleteOrderProduct,
  orderAction,
  getCouriersByCountry,
  // eslint-disable-next-line no-dupe-keys
  removeSuccessMsg,
  
  pauseOrder
})(ViewOrder);
