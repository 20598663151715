import {
  NEXT_PACKAGE_ORDER,
  ADD_PACKAGES,
  GET_PACKER_ORDERS,
  GET_PACKER_DRAFT_ORDERS,
  PRINT_AWB_SUCCESS,
  PRINT_AWB_START,
  PRINT_AWB_STOP
} from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

const startLoading = () => ({
  type: "START_LOADING"
});

const stopLoading = () => ({
  type: "STOP_LOADING"
});

export const getAllOrders = ({ currentPage, itemsPerPage }) => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/packer/orders/?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}`;
  dispatch(startLoading());
  apiCall("get", url)
    .then(resp => dispatch({ type: GET_PACKER_ORDERS, payload: resp }))
    .catch(err => {
      dispatch(addError(err));
      dispatch(stopLoading());
    });
};

export const getDraftOrders = () => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/packer/orders/draft`;
  dispatch(startLoading());

  apiCall("get", url)
    .then(resp => dispatch({ type: GET_PACKER_DRAFT_ORDERS, payload: resp }))
    .catch(err => {
      dispatch(addError(err));
      dispatch(stopLoading());
    });
};

export const getPackerOrder = id => dispatch => {
  const url = `${process.env.REACT_APP_API}/packer/orders/${id}`;
  dispatch(startLoading());
  dispatch(removeError());
  apiCall("get", url)
    .then(resp => dispatch(openOrder(resp.foundOrder)))
    .catch(err => {
      dispatch(addError(err));
      dispatch(stopLoading());
    });
};

export const getNextOrder = ({ history, orderId }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/packer/orders/get/nextorder/?id=${orderId}`;
  dispatch(startLoading());
  dispatch(removeError());
  apiCall("get", url)
    .then(resp => {
      if (resp.foundOrder !== "No orders.") {
        dispatch(openOrder(resp.foundOrder));
        history.push(`/packer/orders/${resp.foundOrder._id}`);
      } else {
        dispatch(stopLoading());
        dispatch(addError("No Orders."));
        history.push(`/packer/orders`);
      }
    })

    .catch(err => {
      dispatch(stopLoading());
      dispatch(addError(err.message));
    });
};

export const orderAction = ({ action, rejectReason, orderId }) => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/packer/orders/${orderId}`;
  apiCall("post", url, { action, rejectReason })
    .then(resp => dispatch(openOrder(resp)))
    .catch(err => dispatch(addError(err.message)));
};

const openOrder = order => ({
  type: ADD_PACKAGES,
  order
});

