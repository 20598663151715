import React from "react";
import { Button, TextArea,Form } from "semantic-ui-react";

function Orderinstructions({ handleAddInstruction,lang,user }) {

  const [addInstructionClicked, setaddInstructionClicked] = React.useState(false);
  const [instruction, setInstructions] = React.useState(null);

  return (
    <div className="actions__secondary-btns vertical--top">
      {/* <Button basic color="grey" onClick={() => handleOrderAction("draft")}>
        <Icon name="list layout" />
        Draft
      </Button> */}
      <div
        className={`reject-btn__wrapper ${
          addInstructionClicked ? "" : "reject-btn__wrapper_closed"
        }`}
      >
        <Button basic color="orange" onClick={() => setaddInstructionClicked(true)}>
          {/* <Icon name="delete" /> */}
  {lang.orders.viewOrder.addinstructionsbutton[user.currentUser.sys_lang]}       
   </Button>

        {addInstructionClicked && (
          <>
            <div>
              <Form>
                <TextArea
                   placeholder=  {lang.orders.viewOrder.packing_instruction_placeholer[user.currentUser.sys_lang]} 
                   value={instruction}
                   onChange ={(e,{value})=>setInstructions(value)}
                ></TextArea>
              </Form>
             
            </div>
            <Button.Group>
            <Button
                onClick={() => {
                  setaddInstructionClicked(false);
                  setInstructions(null);
                }}
              >
                {lang.orders.createOrder.cancelbutton[user.currentUser.sys_lang]}
              </Button>

              <Button
                disabled={!instruction}
                color="grey"
                onClick={() => {
                  setaddInstructionClicked(false);
                  setInstructions(null);
                  handleAddInstruction(instruction);
                  
                }}
              >
              {lang.orders.viewOrder.addinstructionsbutton[user.currentUser.sys_lang]}  
              </Button>
             
            </Button.Group>
          </>
        )}
      </div>
    
    </div>
  );
}
export default Orderinstructions;
