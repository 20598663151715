import React from "react";
import { Table } from "semantic-ui-react";

import ProductItem from "./ProductItem";

function ProductsList(props) {
  const { items, handleDelete, style = {} } = props;

  if (items.length === 0) return null;

  const tableRows = items
    .filter((p) => p !== null)
    .map((p, i) => (
      <ProductItem
        name={p.name}
        quantity={p.quantity}
        sku={p.sku}
        itemId={p._id}
        productId={p.product}
        i={i}
        stockAvailable={p.stockAvailable}
        handleDelete={handleDelete}
      />
    ));

  // console.log("PRODUCT LIST: ORder ID ",orderId)
  return (
    <div className="table-wrapper--horizontal-scroll" style={style}>
      <Table  striped unstackable textAlign="center" padded="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>SKU</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    </div>
  );
}

export default ProductsList;
