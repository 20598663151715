import React from "react";
import { Table, Loader, Header, Dropdown } from "semantic-ui-react";


function Turnovertable({
  stocks,
  isLoading,
  currentPage,
  itemsPerPage,
  days,
  value,
  handleChangeDays,
  style,
  selection = true,
  optionDays = [7,14,30] 
  // isHeaderChecked,
  // checkedItems,
  // handleSingleCheck,
  // handleMultipleCheck,
}) {

  const optionsDaysSelect = optionDays.map(nr => ({
    key: nr,
    value: nr,
    text: nr
  }));

  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!isLoading && !stocks.length)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Header as="h4" textAlign="center">
        No product found
        </Header>
      </div>
    );

  const tableHeader = (
    <Table.Row>
      {/* <Table.HeaderCell>
        <Checkbox checked={isHeaderChecked} onChange={handleMultipleCheck} />
      </Table.HeaderCell> */}
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>SKU</Table.HeaderCell>
      <Table.HeaderCell>NAME</Table.HeaderCell>
      <Table.HeaderCell>Balance</Table.HeaderCell>
      
      <Table.HeaderCell>
            TurnOver
            
            {/* <Dropdown
                  selectOnBlur={false}
                  closeOnChange={true}
                  compact
                  selection={selection}
                  name="days"
                  value={value}
                  onChange={handleChangeDays}
                  options={optionsDaysSelect}
                  style={style}
    /> */}
      </Table.HeaderCell>
     
    </Table.Row>
  );

  const tableBody = stocks.map(
    (
     item,
      i
    ) => (
      <Table.Row key={i}>
        {/* <Table.Cell>
          <Checkbox
            checked={checkedItems.includes(_id)}
            onChange={() => handleSingleCheck(_id)}
          />
        </Table.Cell> */}
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>
        {Object.values(item)[0].sku}
        </Table.Cell>
        <Table.Cell>
       {Object.values(item)[0].name}   
        </Table.Cell>
        <Table.Cell>
          {Object.values(item)[0].balance}
        </Table.Cell>
        
        <Table.Cell>
          {Object.values(item)[0].turnover}
        </Table.Cell>
        
      </Table.Row>
    )
  );

  return (
    <Table unstackable fixed basic="very">
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default Turnovertable;
