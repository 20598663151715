import React, { useEffect, useState } from "react";
import {
  Header,
  Icon,
  Checkbox,
  Button,
 
} from "semantic-ui-react";
import { connect } from "react-redux";

import {
  // getBusinessProfile,
  createBusinessProfile,
  updateBusinessProfile,
} from "../../../actions/businessclient";

import SequencesTable from "./SequencesTable";
import BillingTable from "./BillingTable";
import lang from '../../../lang/config.json'

function ProfileSettings(props) {
  const {
    settings,
    // getBusinessProfile,
    updateBusinessProfile,
    bclientId,
    syslang,createBusinessProfile
  } = props;


  const handleCreateProfileSettings = () => {
    createBusinessProfile(bclientId);
  };

  const handleBusinessProfileUpdate = (e, { name, checked }) => {
    
    updateBusinessProfile({
      id: bclientId,
      update: { [name]: checked },
    });
  };

  if (!(settings && settings._id))
    return (
      <section className="profile-settings">
        <Header as="h3">{lang.settings.company.noprofile[syslang]}</Header>
        <Button onClick={handleCreateProfileSettings}>Add settings</Button>
      </section>
    );

  return (
    <section className="profile-settings">
      <Header as="h3">
        {lang.settings.company.profilesettings[syslang]}
        </Header>
      <Reveal
        isOpen={true}
        header={lang.settings.company.ordersettings[syslang]}
        subHeader={lang.settings.company.ordersubtitle[syslang]}
        body={
          <ul className="settings-list">
            <li>
              <Checkbox
                toggle
                name="orders.invoicing.autoGenerate"
                checked={settings.orders.invoicing.autoGenerate}
                onChange={handleBusinessProfileUpdate}
              />
              {lang.settings.orders.geninvoice[syslang]}
            </li>
            <li>
              <Checkbox
                toggle
                name="orders.invoicing.autoPrint"
                checked={settings.orders.invoicing.autoPrint}
                onChange={handleBusinessProfileUpdate}
              />
              {lang.settings.orders.printinvoice[syslang]}
            </li>
            <li>
              <Checkbox
                toggle
                name="orders.automaticBlockingGoods"
                checked={settings.orders.automaticBlockingGoods}
                onChange={handleBusinessProfileUpdate}
              />
              {lang.settings.orders.autoblockgoods[syslang]}
            </li>
            <li>
              <Checkbox
                toggle
                name="orders.shipping.automaticAssingCourier"
                checked={settings.orders.shipping.automaticAssingCourier}
                onChange={handleBusinessProfileUpdate}
              />
              {lang.settings.orders.autoassigncurrier[syslang]}
            </li>
          </ul>
        }
      />

      <Reveal
        header={lang.settings.company.billing[syslang]}
        subHeader={lang.settings.company.billingsubt[syslang]}
        body={
          <BillingTable
            syslang={syslang}
             lang={lang}
            bclientId={bclientId}
            profileId={settings._id}
            billing={settings.billing}
          />
        }
      />

      <Reveal
        header={lang.settings.company.sequences[syslang]}
        subHeader={lang.settings.company.sequencesub[syslang]}
        body={<SequencesTable syslang={syslang} lang={lang}bclientId={bclientId} profileId={settings._id} />}
      />

      {/* <Header as="h4">
        {lang.settings.company.notifications[syslang]}
        </Header> */}
{/* 
      <Reveal
        header={lang.settings.company.emailnotifications[syslang]}
        // subHeader={lang.settings.company.emailnotsub[syslang]}
        body={
          <ul className="settings-list">
            <li>
              <Checkbox
                toggle
                name="notifications.email.newPo"
                checked={settings.notifications.email.newPo}
                onChange={handleBusinessProfileUpdate}
              />
              New Purchase Order
            </li>
            <li>
              <Checkbox
                toggle
                name="notifications.email.shippingPo"
                checked={settings.notifications.email.shippingPo}
                onChange={handleBusinessProfileUpdate}
              />
              Shipping Purchase Order
            </li>
          </ul>
        }
      /> */}



    </section>
  );
}

function Reveal({ header, subHeader, body,isOpen=false }) {
  const [opened, setOpened] = useState(false);
  const toggleState = () => setOpened((prev) => !prev);

  useEffect(()=>{
    setOpened(isOpen)
  },[isOpen])
  return (
    <div className="reveal">
      <div className="reveal__header" onClick={toggleState}>
        <div>
          <p className="large--text"> {header}</p>
          <span className="small--text grey--text">{subHeader}</span>
        </div>
        <Icon
          name={`angle ${opened ? "up" : "down"}`}
          size="large"
          color="grey"
          className="reveal__header-toggle"
          //   onClick={toggleState}
        />
      </div>

      {opened && <div className="reveal__body">{body}</div>}
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  // settings: reduxState.businessclients.openClient.settings,
  syslang:reduxState.user.currentUser.sys_lang
});

export default connect(mapStateToProps, {
  
  createBusinessProfile,
  updateBusinessProfile,
})(ProfileSettings);
