import React from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

function ProductItem({ i, name, quantity, sku, productId, handleDelete,itemId,stockAvailable }) {
  return (
    <Table.Row key={i} className="">
      <Table.Cell>{i + 1}</Table.Cell>
      <Table.Cell>
        {!stockAvailable && <Icon name="attention" color="yellow" />}
        {stockAvailable && <Icon name="checkmark" color="olive" />}
        <Link to={`/products/${productId}`} target="_blank">
          {name}
        </Link>
      </Table.Cell>
      <Table.Cell>{sku}</Table.Cell>

      <Table.Cell>{quantity}</Table.Cell>
      <Table.Cell>
        <Button
          color="grey"
          basic
          compact
          size="tiny"
          onClick={() => handleDelete(itemId)}
        >
          <Icon name="trash alternate" />
          Delete
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}

export default ProductItem;
