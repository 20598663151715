import React, { useEffect } from "react";
import { Table, Button, Loader } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";

import {
  getSequences,
  createSequence,
  updateSequence,
  deleteSequence,
} from "../../../actions/businessclient";
import { useToggleModalNewEdit } from "../../../hooks/useToggleModalNewEdit";
import SequencesModal from "./SequencesModal";

const defaultInitialFormValues = {
  name: "",
  country: "",
  type: "",
  identifier: 10,
  value: 1,
  positions: 6,
};

function SequencesTable({
  bclientId,
  sequences = [],
  isLoading,
  getSequences,
  createSequence,
  updateSequence,
  deleteSequence,
  lang,syslang
}) {
  const {
    isModalOpen,
    isEditModeOn,
    initFormValues,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaultInitialFormValues);

  useEffect(() => getSequences(bclientId), [bclientId]);

  if (isLoading)
    return (
      <div style={{ position: "relative", padding: "4em" }}>
        <Loader active size="small" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.name[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.country[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.object[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.uniqidetify[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.value[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.length[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.actions[syslang]}</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = sequences.map(
    ({ _id, name, country, type, identifier, value, positions }, i) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">{i + 1}</Table.Cell>

        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{type}</Table.Cell>
        <Table.Cell>{identifier}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
        <Table.Cell>{positions}</Table.Cell>
        <Table.Cell>
          <Button
            compact
            basic
            color="red"
            icon="trash"
            size="mini"
            onClick={() => deleteSequence(_id)}
          />
          <Button
            compact
            basic
            color="grey"
            icon="pencil"
            size="mini"
            onClick={() =>
              openModal(true, {
                _id,
                name,
                country,
                type,
                identifier,
                value,
                positions,
              })
            }
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <>
      {!sequences.length ? (
        <div className="center--text" style={{ padding: "2em" }}>
          <p>{lang.settings.sequences.noresult[syslang]}</p>
          <Button
            style={{ marginTop: "0.5em" }}
            compact
            content={lang.settings.sequences.addnew[syslang]}
            onClick={() => openModal(false)}
          />
        </div>
      ) : (
        <div style={{ padding: "1em" }}>
          <Button
            compact
            floated="right"
            style={{ marginBottom: "1em" }}
            content={lang.settings.sequences.addnew[syslang]}
            onClick={() => openModal(false)}
          />
          <Table basic="very">
            <Table.Header>{tableHeader}</Table.Header>
            <Table.Body>{tableBody}</Table.Body>
          </Table>
        </div>
      )}

      <Modal
        open={isModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{ modal: "" }}
      >
        <SequencesModal
          syslang={syslang}
          lang={lang}
          initFormValues={initFormValues}
          handleClose={closeModal}
          isEditModeOn={isEditModeOn}
          create={(query) =>
            createSequence({ ...query, businessclient: bclientId })
          }
          edit={updateSequence}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  sequences: reduxState.businessclients.sequences,
  isLoading: reduxState.businessclients.sequencesLoading,
});

export default connect(mapStateToProps, {
  getSequences,
  createSequence,
  updateSequence,
  deleteSequence,
})(SequencesTable);
