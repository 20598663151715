import React,{useState} from "react";
import { Form } from "semantic-ui-react";
import ProductFilter from '../../Filters/ProductFilter'
import {Input} from 'rsuite'
import "./productlist.scss";

const AddProduct=(props)=>{

  const [state,setState]=useState({
                                  _id: "",
                                  name:'',
                                  quantity: 0,
                                  price:0,
                                })

  
  const handleSubmit = () => {
            const prodToAdd = {
              product: state._id,
              name:   state.name,
              quantity: Number(state.quantity),
              price:    Number(state.price),
              
            };
            props.handleAddProduct(prodToAdd);
            setState({ _id: "", quantity: 0,price:0,name:'' });
          };


    const {
      style = { marginBottom: "1em" },
      // productsDisabled = false,
      user,
      lang
    } = props;

    
    return (
      // <Segment className="padding-top margin-bottom">
      <Form className="add-product-form" style={style}>

          <div >
            <p>{lang.products.viewproduct.name[user.currentUser.sys_lang]}</p>
          
           <ProductFilter
            style={{width:'500px'}}
            handleChange={(data)=>setState({...state,_id:data._id,name:data.name})}
           ></ProductFilter>
              
            
          </div>
          <div>
            <p>{lang.products.viewproduct.quantity[user.currentUser.sys_lang]}</p>
            <Input
                onChange={(value, e) => setState({...state, quantity: value })}
                value={state.quantity}

            ></Input>
            </div>
       <div>
            <p>{lang.orders.createOrder.producttable.price[user.currentUser.sys_lang]}</p>
            <Input

                onChange={(value, e) => setState({...state, price: value })}
                value={state.price}

            ></Input>
            </div>
            
             <div className="add-product-button">
          <Form.Button
            fluid
            color="grey"
            content={lang.products.list.addproductbutton[user.currentUser.sys_lang]}
            onClick={handleSubmit}
            disabled={!state._id || !state.quantity}
          />
        </div>
      </Form>
      // </Segment>
    );
  }




export default (AddProduct)
