import React, { useState } from "react";
import { Segment } from "semantic-ui-react";
import {
  DatePicker,
  Input,
  IconButton,
  Icon,
  InputGroup,
  SelectPicker,
  Dropdown,
} from "rsuite";
import PageIcon from "@rsuite/icons/Page";

import { countries } from "../../../countries";
import ExportButton from "../../ExportButton";
// import ItemsPerPage from "../../ItemsPerPage";
// import Responsive from "../../Responsive";
import ProductFilter from "../../Filters/ProductFilter";
// import Exportids from './Exportids'

function Filters(props) {
  const {
    // products,
    couriers,
    filters,
    handleUpdateFilter,
    addFilterAndGetOrders,
    handleSearch,
    total,
    lang,
    syslang,
  } = props;

  const [search, setSearch] = useState(null);
  // const [openInputExp,setExportInputOpen] =useState(false)

  const exportmenu = (
    <Dropdown title="Export" placement="leftStart" icon={<PageIcon />}>
      <Dropdown.Item>
        <ExportButton
          total={total}
          filters={filters}
          fileName={`orders_${filters.status}`}
          url="/orders/export/perprodcutline"
          text="Per Product line"
        />
      </Dropdown.Item>

      <Dropdown.Item>
        <ExportButton
          total={total}
          filters={filters}
          fileName={`orders_${filters.status}`}
          url="/orders/export/perorderline"
          text="Per Order line"
        />
      </Dropdown.Item>
      <Dropdown.Item>
        <ExportButton
          total={total}
          filters={filters}
          fileName={`orders_${filters.status}`}
          url="/orders/export/perorderline/cost"
          text="Cost per order"
        />
      </Dropdown.Item>
      {/* <Dropdown.Item onSelect={(key,e)=> setExportInputOpen(true)} > 

      <Exportids openModal={openInputExp} setExportInputOpen={setExportInputOpen} />

                </Dropdown.Item>           */}
    </Dropdown>
  );
  return (
    <div
      className="filters-container_orders filters-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Segment>
        <div
          className="filters-item"
          style={{ maxWidth: "500px", minWidth: "310px" }}
        >
          <InputGroup inside style={{ marginBottom: 10, display: "flex" }}>
            <Input
              placeholder={lang.filters.searchorder[syslang]}
              value={search}
              onChange={(value) => {
                if (!value)
                  handleSearch(value, { name: "searchTerm", value: "" });
                setSearch(value);
              }}
            />
            <IconButton
              onClick={(e) =>
                handleSearch(e, { name: "searchTerm", value: search })
              }
              size="md"
              icon={<Icon icon="search" />}
            />
          </InputGroup>
          {/* <Button
            icon="eraser"
            onClick={e =>
              this.handleSearch(e, { name: "searchTerm", value: "" })
            }
          /> */}
        </div>

        <div className="">
          <div className="flex--left">
            <SelectPicker
              placeholder={lang.filters.country[syslang]}
              style={{ width: "275px" }}
              onChange={(value) =>
                handleUpdateFilter({ value, name: "country" })
              }
              data={countries.map((item) => ({
                value: item,
                label: item,
              }))}
              value={filters.country}
            />

            <SelectPicker
              placeholder={lang.filters.courier[syslang]}
              style={{ width: "275px" }}
              onChange={(value) =>
                handleUpdateFilter({ value, name: "courier" })
              }
              data={couriers.map(({ _id, name }) => ({
                value: _id,
                label: name,
              }))}
              value={filters.courier}
            />

            {/* <ProductFilter
                  placeholder={lang.filters.product[syslang]}
                  style={{width:"275px"}}
                  handleChange={(data)=>handleUpdateFilter({value:data._id,name:'product'})}
              /> */}
            {/* <SelectPicker
                placeholder={lang.filters.product[syslang]}
                style={{width:"275px"}}
                  onChange={(value)=>handleUpdateFilter({value,name:'product'})}
                  data={products.map(({ _id, name }) => ({
                    value: _id,
                    label: name

                  }))}
                  
                  /> */}
          </div>
        </div>
      </Segment>
      <div className="flex--between">
        <div className="flex--left">
          <DatePicker
            oneTap
            value={filters.dateFrom}
            isClearable={true}
            style={{ width: "200px" }}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => addFilterAndGetOrders({ dateFrom: date })}
            placeholder={lang.filters.datepicker.from[syslang]}
          ></DatePicker>

          <DatePicker
            oneTap
            value={filters.dateTo}
            style={{ width: "200px" }}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => addFilterAndGetOrders({ dateTo: date })}
            placeholder={lang.filters.datepicker.to[syslang]}
          ></DatePicker>
        </div>

        {exportmenu}

        {/* <div className="flex--right orders-actions-rigth">
           <Responsive
            desktopComponent={
              <span style={{ marginRight: "0.6em" }}>
                  <ExportButton
                      total={total}
                      filters={filters}
                      fileName="orders"
                      url="/orders/export"
                      // mapData={mapData}
                    />
                
              </span>
            }
            breakpoint={600}
          />

          <ItemsPerPage
            total={total}
            // style={{ height: "1em" }}
            value={filters.itemsPerPage}
            handleChange={handleUpdateFilter}
          />
        </div> */}
      </div>
    </div>
  );
}

export default Filters;
