import React,{useState,useEffect} from "react";
import {
  Segment,
  Grid,
  Header,
  Divider,
  Checkbox,
  Label
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import EditableField from "./EditableField";
import { countries } from "../../../countries";
import { statusColor } from "../ListOrders/OrdersTable";
import CourierFilter from '../../Filters/CourierFilter'
import ExportButton from './export'

const optionsCountries = [
  { key: "No country", value: null, text: "" },
  ...countries.map(c => ({ key: c, value: c, text: c }))
];

const sh = "shipping.shipTo.";

function Order(props) {
  const {
    lang,user,
    handleContentUpdate,
    handleCheck,
    handleAdressCheck,
    orderUpdates,
    order,
    isAdressAsShipping,
    
  } = props;

    const [courier, setCourier] = useState()
 
  
    const handleCourier =(e,{value})=>{
      
      handleContentUpdate('shipping.courier',value)
      setCourier(value)
    }

 useEffect(()=>{
    
    setCourier(order.shipping.courier && order.shipping.courier._id ? order.shipping.courier._id : order.shipping.courier)
  },[order])

  const isCompany =
    orderUpdates[sh + "client.isCompany"] === true ||
    orderUpdates[sh + "client.isCompany"] === false
      ? orderUpdates[sh + "client.isCompany"]
      : order.shipping.shipTo &&
        order.shipping.shipTo.client &&
        order.shipping.shipTo.client.isCompany;
  const CODapply =
    orderUpdates["payment.COD.apply"] === true ||
    orderUpdates["payment.COD.apply"] === false
      ? orderUpdates["payment.COD.apply"]
      : order.payment && order.payment.COD && order.payment.COD.apply;
  const CODcard =
    orderUpdates["payment.COD.card"] === true ||
    orderUpdates["payment.COD.card"] === false
      ? orderUpdates["payment.COD.card"]
      : order.payment && order.payment.COD && order.payment.COD.card;

  if (!Object.keys(order).length) {
    return null;
  } else {
    return (
      <>
        <Segment>
          <div className='flex--between'>
            <div><Header className="form__header" as="h3">
            <Label
              ribbon
              style={{ transform: "translate(-7px, -2px)" }}
              color={statusColor[order.status]}
            >
              {order.status}
            </Label>
            <span className="grey--text small--text">
{lang.orders.viewOrder.ordernr[user.currentUser.sys_lang]}

</span>{" "}
            {order.clientOrderNr} - status: {order.status}
                 
                 
          </Header></div>
            <div>
            <ExportButton
                    data={order}
                  ></ExportButton>
            </div>
          </div>
          
          <div className="view-form__wrapper">
            <div className="view-form__columns">
              <div className="form-columns__client">
                <Header as="h3">{lang.orders.viewOrder.titleinfo[user.currentUser.sys_lang]}
</Header>
                <Divider />
                <Grid columns={2}>
                  <FormItem
                    width={11}
                    label={lang.orders.viewOrder.firstname[user.currentUser.sys_lang]}
                    name={sh + "client.firstName"}
                    initialValue={
                      orderUpdates[sh + "client.firstName"] ||
                      (order.shipping.shipTo.client &&
                        order.shipping.shipTo.client.firstName)
                    }
                    handleContentUpdate={handleContentUpdate}
                  />
                  <FormItem
                    width={11}
                    label={lang.orders.viewOrder.lastname[user.currentUser.sys_lang]}
                    name={sh + "client.lastName"}
                    initialValue={
                      orderUpdates[sh + "client.lastName"] ||
                      (order.shipping.shipTo.client &&
                        order.shipping.shipTo.client.lastName)
                    }
                    handleContentUpdate={handleContentUpdate}
                  />
                  <FormItem
                    width={11}
                    label={lang.orders.viewOrder.phone[user.currentUser.sys_lang]}
                    name={sh + "phone"}
                    initialValue={
                      orderUpdates[sh + "phone"] || order.shipping.shipTo.phone
                    }
                    handleContentUpdate={handleContentUpdate}
                  />
                </Grid>
              </div>

              <div className="form-columns__adress">
                <Header as="h3">{lang.orders.viewOrder.shippingaddress[user.currentUser.sys_lang]}</Header>
                <Divider />
                <Grid columns={2} stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Grid columns={2}>
                        <FormItem
                          label={lang.orders.viewOrder.country[user.currentUser.sys_lang]}
                          options={optionsCountries}
                          name={sh + "country"}
                          initialValue={
                            orderUpdates[sh + "country"] ||
                            order.shipping.shipTo.country
                          }
                          handleContentUpdate={handleContentUpdate}
                        />
                        <FormItem
                          label={lang.orders.viewOrder.province[user.currentUser.sys_lang]}
                          name={sh + "province"}
                          initialValue={
                            orderUpdates[sh + "province"] ||
                            order.shipping.shipTo.province
                          }
                          handleContentUpdate={handleContentUpdate}
                        />
                        <FormItem
                          label={lang.orders.viewOrder.city[user.currentUser.sys_lang]}
                          name={sh + "city"}
                          initialValue={
                            orderUpdates[sh + "city"] ||
                            order.shipping.shipTo.city
                          }
                          handleContentUpdate={handleContentUpdate}
                        />
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid columns={2}>
                        <FormItem
                          label={lang.orders.viewOrder.postcode[user.currentUser.sys_lang]}
                          name={sh + "postcode"}
                          initialValue={
                            orderUpdates[sh + "postcode"] ||
                            order.shipping.shipTo.postcode
                          }
                          handleContentUpdate={handleContentUpdate}
                        />
                        <FormItem
                          label={lang.orders.viewOrder.street[user.currentUser.sys_lang]}
                          name={sh + "street"}
                          initialValue={
                            orderUpdates[sh + "street"] ||
                            order.shipping.shipTo.street
                          }
                          handleContentUpdate={handleContentUpdate}
                        />
                        <FormItem
                          label={lang.orders.viewOrder.number[user.currentUser.sys_lang]}
                          name={sh + "streetNr"}
                          initialValue={
                            orderUpdates[sh + "streetNr"] ||
                            order.shipping.shipTo.streetNr
                          }
                          handleContentUpdate={handleContentUpdate}
                        />
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>

            <Checkbox
              label={lang.orders.viewOrder.iscompany[user.currentUser.sys_lang]}
              style={{ paddingRight: "2.5em" }}
              name={sh + "client.isCompany"}
              value={isCompany}
              checked={isCompany}
              onChange={handleCheck}
            />

            {isCompany && (
              <>
                <Checkbox
                  label={lang.orders.viewOrder.sameaddress[user.currentUser.sys_lang]}
                  name={"isAdressAsShipping"}
                  value={isAdressAsShipping}
                  checked={isAdressAsShipping}
                  onChange={handleAdressCheck}
                />

                {/* Company Info */}
                {/* <Header as="h3">Company:</Header> */}
                <Divider />

                <div className="view-form__columns">
                  <div className="form-columns__client">
                    <Grid>
                      <FormItem
                        width={11}
                        label={lang.orders.viewOrder.companyname[user.currentUser.sys_lang]}
                        name={sh + "client.companyName"}
                        initialValue={
                          orderUpdates[sh + "client.companyName"] ||
                          (order.shipping.shipTo.client &&
                            order.shipping.shipTo.client.companyName)
                        }
                        handleContentUpdate={handleContentUpdate}
                      />
                      <FormItem
                        width={11}
                        label={lang.orders.viewOrder.phone[user.currentUser.sys_lang]}
                        name={sh + "client.phone"}
                        initialValue={
                          orderUpdates[sh + "client.phone"] ||
                          (order.shipping.shipTo.client &&
                            order.shipping.shipTo.client.phone)
                        }
                        handleContentUpdate={handleContentUpdate}
                      />
                      <FormItem
                        width={11}
                        label={lang.orders.viewOrder.taxid[user.currentUser.sys_lang]}
                        name={sh + "client.taxId"}
                        initialValue={
                          orderUpdates[sh + "client.taxId"] ||
                          (order.shipping.shipTo.client &&
                            order.shipping.shipTo.client.taxId)
                        }
                        handleContentUpdate={handleContentUpdate}
                      />
                    </Grid>
                  </div>

                  {/* Company Adress  */}
                  <div className="form-columns__adress">
                    <Grid columns={2} stackable>
                      <Grid.Row>
                        <Grid.Column>
                          <Grid columns={2}>
                            <FormItem
                              label={lang.orders.viewOrder.country[user.currentUser.sys_lang]}
                              options={optionsCountries}
                              name={sh + "client.country"}
                              initialValue={
                                orderUpdates[sh + "client.country"] ||
                                (order.shipping.shipTo.client &&
                                  order.shipping.shipTo.client.country)
                              }
                              handleContentUpdate={handleContentUpdate}
                            />
                            <FormItem
                              label={lang.orders.viewOrder.province[user.currentUser.sys_lang]}
                              name={sh + "client.province"}
                              initialValue={
                                orderUpdates[sh + "client.province"] ||
                                (order.shipping.shipTo.client &&
                                  order.shipping.shipTo.client.province)
                              }
                              handleContentUpdate={handleContentUpdate}
                            />
                            <FormItem
                              label={lang.orders.viewOrder.city[user.currentUser.sys_lang]}
                              name={sh + "client.city"}
                              initialValue={
                                orderUpdates[sh + "client.city"] ||
                                (order.shipping.shipTo.client &&
                                  order.shipping.shipTo.client.city)
                              }
                              handleContentUpdate={handleContentUpdate}
                            />
                          </Grid>
                        </Grid.Column>

                        <Grid.Column>
                          <Grid>
                            <FormItem
                              label={lang.orders.viewOrder.postcode[user.currentUser.sys_lang]}
                              name={sh + "client.postcode"}
                              initialValue={
                                orderUpdates[sh + "client.postcode"] ||
                                (order.shipping.shipTo.client &&
                                  order.shipping.shipTo.client.postcode)
                              }
                              handleContentUpdate={handleContentUpdate}
                            />
                            <FormItem
                              label={lang.orders.viewOrder.street[user.currentUser.sys_lang]}
                              name={sh + "client.street"}
                              initialValue={
                                orderUpdates[sh + "client.street"] ||
                                (order.shipping.shipTo.client &&
                                  order.shipping.shipTo.client.street)
                              }
                              handleContentUpdate={handleContentUpdate}
                            />
                            <FormItem
                              label={lang.orders.viewOrder.number[user.currentUser.sys_lang]}
                              name={sh + "client.streetNr"}
                              initialValue={
                                orderUpdates[sh + "client.streetNr"] ||
                                (order.shipping.shipTo.client &&
                                  order.shipping.shipTo.client.streetNr)
                              }
                              handleContentUpdate={handleContentUpdate}
                            />
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              </>
            )}
          </div>
        </Segment>

      <div class='flex--between'>  
      <Segment compact style={{width:'250px',margin:'5px'}}>
          <div className="">             
                <Header as="h3">{lang.orders.viewOrder.payment[user.currentUser.sys_lang]}</Header>
                            
                <Divider />
                <p>Total amount: {order.payment.total?order.payment.total:"NA"}</p>
                <p>Type: {order.payment.type?order.payment.type:"NA"}</p>
                {!CODapply &&<div>Online payment</div>}
                <Checkbox
                  label={lang.orders.viewOrder.cashondelivery[user.currentUser.sys_lang]}

                  name={"payment.COD.apply"}
                  checked={CODapply}
                  onChange={handleCheck}
                />
                {CODapply && (
                

                  <Grid columns="two">
                    <p>Init COD value || {order.payment.COD.amountInit}</p>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        {/* <Checkbox
                          label="Accept Cards"
                          name={"payment.COD.paymentcard"}
                          checked={CODcard}
                          onChange={handleCheck}
                        /> */}
                      </Grid.Column>
                    </Grid.Row>

                    <FormItem
                      width={10}
                      label={lang.orders.viewOrder.totalamount[user.currentUser.sys_lang]}
                      name={"payment.COD.amount"}
                      initialValue={
                        orderUpdates["payment.COD.amount"] ||
                        (order.payment &&
                          order.payment.COD &&
                          order.payment.COD.amount)
                      }
                      handleContentUpdate={handleContentUpdate}
                    />
                  </Grid>
                )}
              </div>
</Segment>
      <Segment compact style={{width:'250px',margin:'5px'}}>
          <div>
              <Header as="h3">{lang.orders.viewOrder.shipping[user.currentUser.sys_lang]}
                </Header>
                <Divider />
                <Grid columns={2}>

                <CourierFilter
                    value={courier}
                    handleChange={handleCourier}
                    // initialValue={
                    //         orderUpdates["shipping.courier"] ||
                    //         order.shipping.courier
                    //       }    
                >
                  
                </CourierFilter>
                {/* <div>Weight(grams): {order.shipping.weight}</div> */}

                <FormItem
                  editable      = {true}
                  width         = {12}
                  label={lang.orders.viewOrder.trackingid[user.currentUser.sys_lang]}

                  name          = {"shipping.trackingId"}
                  initialValue  = {orderUpdates["shipping.trackingId"] || order.shipping.trackingId}
                  handleContentUpdate
                                ={handleContentUpdate}
                />
                </Grid>
              </div>
            </Segment>
      <Segment compact style={{width:'250px',margin:'5px'}}>
            <div>
                <Header as="h3">{lang.orders.viewOrder.invoice[user.currentUser.sys_lang]}</Header>
                <Divider />
                {!order.invoice &&<div>
                                 {lang.orders.viewOrder.invoiceserviceoff[user.currentUser.sys_lang]}
                                  </div>}
                {order.invoice && ( 
                    <div>
                        <Link to={`/invoices/${order.invoice._id}`} target="_blank">
                          {lang.orders.viewOrder.invoice[user.currentUser.sys_lang]}: {order.invoice.sequenceNumber ? order.invoice.sequenceNumber :"DRAFT"}
                        </Link>
                        {order.invoice.creditNote &&<div>{lang.orders.viewOrder.creditnote[user.currentUser.sys_lang]} {order.invoice.creditNote.sequenceNumber}</div>}
                    </div>
                        )}
                  
                  {order.print.length>0 &&<div style={{marginTop:'10px'}}>
                  <Header as="h3">{lang.orders.viewOrder.forprint[user.currentUser.sys_lang]}</Header>
                <Divider />
                
                {order.print.map((item,index)=>(<li>{item.name?item.name:index} - <a href={item.url}>view</a></li>))}
                </div>}
                
              </div>
         
        </Segment>
        </div>
      </>
    );
  }
}

export default Order;

const FormItem = ({
  label,
  name,
  initialValue,
  width = 12,
  handleContentUpdate,
  options = null
}) => (
  <Grid.Row>
    <Grid.Column width={16 - width}>
      <label style={{ paddingRight: "0.2em", marginTop: "7px" }}>{label}</label>
    </Grid.Column>
    <Grid.Column width={width}>
      <EditableField
        label={label}
        name={name}
        initialValue={initialValue}
        handleContentUpdate={handleContentUpdate}
        options={options}
      />
    </Grid.Column>
  </Grid.Row>
);
