import React, { useState } from "react";
import { Input, Button, Header } from "semantic-ui-react";

import CountryFilter from "../../Filters/CountryFilter";

const requieredFields = ["country", "type"];

function SequencesModal({
  initFormValues,
  isEditModeOn,
  handleClose,
  edit,
  create,
  syslang,lang
}) {
  const [formValues, setFormValues] = useState(initFormValues);
  const [touched, setTouched] = useState([]);
  const [validationError, setValidationError] = useState(false);

  const handleChange = (e, d) => {
    const { name, value } = d;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setTouched((prev) => [...prev, name]);
  };

  const handleAction = () => {
    const hasEmptyRequierdField = requieredFields.find(
      (field) => !formValues[field]
    );
    if (hasEmptyRequierdField) {
      setValidationError(true);
      return;
    }
    const query = {};
    for (let field of touched) {
      query[field] = formValues[field];
    }
    isEditModeOn
      ? edit({ id: formValues._id, update: query })
      : create(formValues);
    handleClose();
  };

  return (
    <section>
      <Header as="h4">
        {!isEditModeOn ? "Add new" : "Update"} sequences:
        <p className="small--text grey--text">[identifier][possitions+start]</p>
      </Header>
      <div className="quotations-modal-grid">
        <div className="quotations-modal-label-input">
          <label>{lang.table.name[syslang]}</label>
          <Input
            type="text"
            placeholder="ex:Issued invoices..."
            value={formValues.name}
            name="name"
            onChange={handleChange}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>
           {lang.table.country[syslang]} <span className="new-ticket-form__required-symbol">*</span>
          </label>
          <CountryFilter
            value={formValues.country}
            handleChange={handleChange}
            error={validationError && !formValues.country}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>
          {lang.table.object[syslang]}<span className="new-ticket-form__required-symbol">*</span>
          </label>
          <Input
            type="text"
            placeholder = 'ex:Invoice,Bill...'
            value={formValues.type}
            name="type"
            onChange={handleChange}
            error={validationError && !formValues.type}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>{lang.table.uniqidetify[syslang]}</label>
          <Input
            type="number"
            min="0"
            value={formValues.identifier}
            name="identifier"
            onChange={handleChange}
          />
        </div>

        <div className="quotations-modal-label-input">
        <label>{lang.table.startat[syslang]}</label>
          <Input
            type="number"
            min="0"
            value={formValues.value}
            name="value"
            onChange={handleChange}
          />
        </div>

        <div className="quotations-modal-label-input">
        <label>{lang.table.length[syslang]}</label>
          <Input
            type="number"
            min="0"
            value={formValues.positions}
            name="positions"
            onChange={handleChange}
          />
        </div>
      </div>
      {validationError && (
        <span className="user-form__error-msg">
          Fill in the required fields!
        </span>
      )}
      <Button
        floated="right"
        primary
        content={!isEditModeOn ? "Create" : "Update"}
        onClick={() => handleAction({ formValues })}
      />
      <Button floated="right" content="Cancel" onClick={handleClose} />
    </section>
  );
}

export default SequencesModal;
