import React, { useState, useEffect } from "react";
import {
  Header,
  Message,
  Label,
  Button,
  Segment,
  Divider,
  Input,
  Checkbox,
} from "semantic-ui-react";
import { Alert } from "rsuite";
import Moment from "react-moment";
// import CanView from "../../../../role-based-access/CanView";
import { Link } from "react-router-dom";

import Newrefund from "./addrefund";
import Comments from "../../../Comments";

import EcontValidator from "./econtAddressValidator";
import Parcelshops from "./parcelshops";
import CancelOrderModal from "./CancelOrderModal";
import ProductsList from "./ProductsList";
import AddProduct from "./AddProduct";
import ChangeLogs from "./ChangeLogs";
import {
  getOrderV2,
  addOrderProductV2,
  updateOrderV2,
  deleteOrderProductV2,
  cancelOrder,
  duplicateOrder,
} from "../../../../actions/orders";
import CountryFilter from "../../../Filters/CountryFilter";
import WarehouseFilter from "../../../Filters/WarehouseFilter";
import Services from "./Services";
import lang from "../../../../lang/config.json";

import { DatePicker } from "rsuite";
import CourierFilter from "../../../Filters/CourierFilter";

const Order = ({ user, match }) => {
  const [order, setOrder] = useState(null);
  // const [isFormTouched, setFormTouched] = useState(false);
  const [showAddRefund, setRefundOpen] = useState(false);
  const [addProduct, setAddproduct] = useState(false);
  const [updateField, setShowUpdateField] = useState({
    name: null,
    value: null,
  });

  const [updatingSegment, setUpdatingSegment] = useState();
  const [isEcontValidator, setEcontValidatoOpen] = useState(false);
  // const [validatingAddress, setValidatingaddress] = useState(false);
  const [isModalOpen, showCancelModal] = useState(false);

  const handleCheckCOD = (checked) => {
    setUpdatingSegment("payment");
    updateOrderV2({
      query: { "payment.COD.apply": checked },
      id: order._id,
    })
      .then((order) => {
        setOrder(order);
        setUpdatingSegment(null);
      })
      .catch((err) => setUpdatingSegment(null));
  };

  const duplicate = (id) => {
    setUpdatingSegment("actions");

    duplicateOrder(id).then((resp) => {
      setUpdatingSegment(false);
      window.open(`/orders/${resp._id}`);
    });
  };

  const changeStatus = (status) => {
    setUpdatingSegment("actions");
    updateOrderV2({
      query: { status },
      id: order._id,
    })
      .then((order) => {
        setOrder(order);
        setUpdatingSegment(null);
      })
      .catch((err) => setUpdatingSegment(null));
  };

  const handleCancel = (id) => {
    cancelOrder(id)
      .then((resp) => setOrder(resp))
      .catch((error) => Alert.error(error));
  };
  // const handleOrderAction = action => {
  //   setUpdatingSegment("actions");
  //   if (action)
  //     orderActionV2({
  //       action,
  //       orderId: order._id,
  //     })
  //       .then((order) => {
  //         if (order.message) Alert.error(order.message);
  //         else setOrder(order);

  //         setUpdatingSegment(false);
  //       })
  //       .catch((err) => {
  //         Alert.error(err.message);
  //       });
  //   else setUpdatingSegment(false);
  // };

  const handleSelectedParcelShop = (value) => {
    setUpdatingSegment("address");
    updateOrderV2({
      query: {
        "api_request.to_office": value ? 1 : 0,
        "api_request.office_code": value,
        // "api_request.name":name,
      },
      id: order._id,
    })
      .then((order) => {
        setOrder(order);
        setUpdatingSegment(null);
      })
      .catch((err) => setUpdatingSegment(null));
  };

  const handleUpdateField = (segment) => {
    setUpdatingSegment(segment);
    updateOrderV2({
      query: { [updateField.name]: updateField.value },
      id: order._id,
    })
      .then((order) => {
        setOrder(order);
        setUpdatingSegment(null);
      })
      .catch((err) => setUpdatingSegment(null));

    setShowUpdateField({ name: null, value: null });
  };

  const handleAddOrderProduct = (item) => {
    setUpdatingSegment("products");
    addOrderProductV2(order._id, item).then((order) => {
      setOrder(order);
      setUpdatingSegment(false);
    });
  };

  const handleDeleteItem = (itemId) => {
    setUpdatingSegment("products");
    deleteOrderProductV2({
      itemId,
      orderId: order._id,
    }).then((order) => {
      setOrder(order);
      setUpdatingSegment(false);
    });
  };


  useEffect(() => {
    getOrderV2(match.params.id).then((resp) => setOrder(resp));
  }, [match.params.id]);

  if (!order) return <div>loading...</div>;

  return (
    <div className="flex--column" style={{ maxWidth: "900px", margin: "15px" }}>
      <segment name="errors">
        {order.errMsgs &&
          order.errMsgs.map((item, index) => (
            <div className="alert alert-danger" key={index}>
              {item}
            </div>
          ))}
      </segment>

      <segment name="header">
        <div className="flex--between vertical--center">
          <div style={{ marginTop: "10px", marginLeft: "0" }}>
            <div className="flex_spaceBetween" style={{ height: "30px" }}>
              <Header as="h3">Status:</Header>
              <Label>{order.status}</Label>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span># {order.clientOrderNr}</span>

              <span className="grey--text small--text  ml--2">
                created at:{" "}
                <Moment format="DD-MM-YY HH:mm">{order.createdAt}</Moment>
              </span>
            </div>
          </div>

          {order.processing.instructions && (
            <Message warning size="big">
              <Message.Header>Packing Instructions!</Message.Header>
              <p>{order.processing.instructions}</p>
              <Label floating>
                {/* <button onClick={this.handleRemoveInstruction}>x</button> */}
              </Label>
            </Message>
          )}
        </div>
      </segment>

      <Segment name="actions" loading={updatingSegment === "actions"}>
        <div>
          <div
            className="flex--between"
            style={{ margin: "5px", rowGap: "8px" }}
          >
            <div>
              <Button color='orange'onClick={() => duplicate(order._id)}>Dublicate</Button>

              <Button onClick={() => setRefundOpen(true)}>
                {lang.orders.viewOrder.refundbutton[user.currentUser.sys_lang]}
              </Button>

              {["Client editing", "Draft"].includes(order.status) && (
                <Button
                  color="green"
                  onClick={() => {
                    changeStatus("New");
                  }}
                >
                  {
                    lang.orders.viewOrder.validatebutton[
                      user.currentUser.sys_lang
                    ]
                  }
                </Button>
              )}
              {["Order Ready for picking", "Product Picking"].includes(
                order.status
              ) && (
                <Button color="yellow" onClick={() => changeStatus("Draft")}>
                  {lang.orders.viewOrder.draftbutton[user.currentUser.sys_lang]}
                </Button>
              )}
              {[
                "Order Ready for picking",
                "Product Picking",
                "Draft",
                "Client editing",
                "New",
                "No goods"
              ].includes(order.status) && (
                <Button color="red" onClick={() => showCancelModal(true)}>
                  {
                    lang.orders.viewOrder.cancelbutton[
                      user.currentUser.sys_lang
                    ]
                  }
                </Button>
              )}
            </div>

            {/* <Button color="red" onClick={() => showCancelModal(true)}>
                Cancel Order
              </Button> */}

            <CancelOrderModal
              handleOrderAction={(action) => {
                showCancelModal(false);
                handleCancel(order._id);
              }}
              isModalOpen={isModalOpen}
              handleCloseModal={() => showCancelModal(false)}
              _id={order._id}
            />
          </div>
        </div>
      </Segment>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          columnGap: "20px",
          justifyContent: "space-between",
        }}
      >
        <Segment
          name="address"
          loading={updatingSegment == "address"}
          style={{ maxWidth: "440px", width: "440px", marginTop: "14px" }}
        >
          <div className="flex--between">
            <Header>Client Address</Header>
            {/* <Button
              color="green"
              loading={validatingAddress}
              onClick={() => validateOrder(order._id)}
            >
              Validate Address{" "}
            </Button> */}
          </div>

          <Divider></Divider>
          <table>
            <tr id="fistName" style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>First name</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.client.firstName" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.client.firstName" &&
                  order.shipping.shipTo.client.firstName}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.client.firstName" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.client.firstName" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.client.firstName",
                        value: order.shipping.shipTo.client.firstName,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="lastName" style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>Last name</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.client.lastName" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.client.lastName" &&
                  order.shipping.shipTo.client.lastName}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.client.lastName" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.client.lastName" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.client.lastName",
                        value: order.shipping.shipTo.client.lastName,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="phone" style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>Phone</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.phone" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.phone" &&
                  order.shipping.shipTo.phone}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.phone" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.phone" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.phone",
                        value: order.shipping.shipTo.phone,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="country " style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>Country</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.country" && (
                  <div>
                    <CountryFilter
                      value={updateField.value}
                      handleChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    />
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.country" &&
                  order.shipping.shipTo.country}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.country" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.country" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.country",
                        value: order.shipping.shipTo.country,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="province " style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>Province</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.province" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.province" &&
                  order.shipping.shipTo.province}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.province" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.province" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.province",
                        value: order.shipping.shipTo.province,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="city" style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>City</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.city" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.city" &&
                  order.shipping.shipTo.city}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.city" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.city" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.city",
                        value: order.shipping.shipTo.city,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="street" style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>Street</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.street" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.street" &&
                  order.shipping.shipTo.street}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.street" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.street" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.street",
                        value: order.shipping.shipTo.street,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="streetNr" style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>Street Nr</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.streetNr" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.streetNr" &&
                  order.shipping.shipTo.streetNr}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.streetNr" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.streetNr" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.streetNr",
                        value: order.shipping.shipTo.streetNr,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>
            <tr id="postcode" style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>Post code</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipTo.postcode" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shipTo.postcode" &&
                  order.shipping.shipTo.postcode}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipTo.postcode" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("address")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipTo.postcode" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipTo.postcode",
                        value: order.shipping.shipTo.postcode,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>
          </table>

          {order.shipping.courier &&
            (order.shipping.courier.name === "ECONT" ||
              order.shipping.courier.name === "ECONT_GR") && (
              <Segment
                name="econtValidator"
                loading={updatingSegment == "econtValidator"}
              >
                <button onClick={() => setEcontValidatoOpen(!isEcontValidator)}>
                  Econt validator
                </button>
                {isEcontValidator && (
                  <EcontValidator
                    country={order.shipping.shipTo.country}
                    oldCity={order.shipping.shipTo.city}
                    oldProvince={order.shipping.shipTo.province}
                    oldPostcode={order.shipping.shipTo.postcode}
                    oldStreet={order.shipping.shipTo.street}
                    oldStreetNr={order.shipping.shipTo.streetNr}
                    handleToggleModal={() =>
                      setEcontValidatoOpen(!isEcontValidator)
                    }
                    isEcontValidator={isEcontValidator}
                    selectedAddress={(data) => {
                      // console.log("CALL BACK DATA:", data);
                      setUpdatingSegment("address");
                      updateOrderV2({
                        query: {
                          "shipping.shipTo.city": data.newCity,
                          "shipping.shipTo.province": data.newProvice,
                          "shipping.shipTo.postcode": data.newPostcode,
                          "shipping.shipTo.street": data.newStreet,
                          "shipping.shipTo.streetNr": data.newStreetNr,
                        },
                        id: order._id,
                      })
                        .then((order) => {
                          setOrder(order);
                          setUpdatingSegment(null);
                        })
                        .catch((err) => setUpdatingSegment(null));
                    }}
                  ></EcontValidator>
                )}

                <Parcelshops
                  country={order.shipping.shipTo.country}
                  courierId={order.shipping.courier._id || ""}
                  isOffice={
                    order.api_request && order.api_request.to_office === 1
                      ? true
                      : false
                  }
                  office={
                    order.api_request && order.api_request.office_code
                      ? order.api_request.office_code
                      : ""
                  }
                  selectedOffice={(data) => {
                    handleSelectedParcelShop(data);
                  }}
                ></Parcelshops>
              </Segment>
            )}
        </Segment>

        <Segment
          name="shipping"
          loading={updatingSegment == "shipping"}
          style={{ maxWidth: "440px" }}
        >
          <Header as="h3">Shipping data</Header>
          <Divider />

          <table>
            <tr id="shippingCost" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Price</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shippingCost" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.shippingCost" &&
                  order.shipping.shippingCost}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shippingCost" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("shipping")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shippingCost" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shippingCost",
                        value: order.shipping.shippingCost,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="warehouse" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Warehouse </td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.shipFrom" && (
                  <div>
                    <WarehouseFilter
                      defaultOpen={true}
                      multiple={false}
                      value={updateField.value}
                      handleChange={(e, { value }) => {
                        setShowUpdateField({
                          name: "shipping.shipFrom",
                          value,
                        });
                      }}
                    ></WarehouseFilter>
                  </div>
                )}
                {updateField.name !== "shipping.shipFrom" &&
                order.shipping.shipFrom &&
                order.shipping.shipFrom.name
                  ? order.shipping.shipFrom.name
                  : null}
              </td>

              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.shipFrom" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("shipping.shipFrom")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.shipFrom" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.shipFrom",
                        value: order.shipping.shipFrom,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="courier" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Courier </td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.courier" && (
                  <div>
                    <CourierFilter
                      defaultOpen={true}
                      multiple={false}
                      value={updateField.value}
                      handleChange={(e, { value }) => {
                        setShowUpdateField({
                          name: "shipping.courier",
                          value,
                        });
                      }}
                    ></CourierFilter>
                  </div>
                )}
                {updateField.name !== "shipping.courier" && (
                  <div>
                    {order.shipping.courier && order.shipping.courier.name
                      ? order.shipping.courier.name
                      : null}{" "}
                  </div>
                )}
              </td>

              {/* <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.courier" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("shipping")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.courier" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.courier",
                        value: order.shipping.courier
                      });
                    }}
                  ></Button>
                )}
              </td> */}
            </tr>

            <tr id="deliveryDate" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>
                Delivery Date
              </td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.deliveryDate" && (
                  <div>
                    <DatePicker
                      style={{ width: "200px" }}
                      oneTap
                      // placeholder="select"
                      value={updateField.value}
                      format="DD-MM-YYYY"
                      // ranges={[dateFrom,dateTo]}
                      onChange={(value) =>
                        setShowUpdateField({
                          name: "shipping.deliveryDate",
                          value,
                        })
                      }
                    />
                  </div>
                )}
                {updateField.name !== "shipping.deliveryDate" && (
                  <div>
                    <Moment
                      //  className="small--text"
                      format="DD-MM-YY HH:mm"
                      style={{ display: "block" }}
                    >
                      {order.shipping.deliveryDate}
                    </Moment>
                  </div>
                )}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.deliveryDate" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("shipping")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.deliveryDate" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.deliveryDate",
                        value: order.shipping.deliveryDate,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="trackingId" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>
                AWB (courierId)
              </td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipping.trackingId" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "shipping.trackingId" &&
                  order.shipping.trackingId}{" "}
                |
                {order.shipping.tracking_awbPdf && (
                  <a href={order.shipping.tracking_awbPdf} target="_blank">
                    download
                  </a>
                )}
              </td>
              {/* <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipping.trackingId" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("shipping")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipping.trackingId" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipping.trackingId",
                        value: order.shipping.trackingId
                      });
                    }}
                  ></Button>
                )}
              </td> */}
            </tr>

            <tr id="Tracking" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Tracking</td>
              <td style={{ textAlign: "left" }}>
                <a
                  href={order.shipping.trackingUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  view
                </a>
              </td>
            </tr>
            <tr id="weight" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Weight (g)</td>
              <td style={{ textAlign: "left" }}>{order.shipping.weight}</td>
            </tr>
          </table>
        </Segment>

        <Segment
          name="products"
          loading={updatingSegment == "products"}
          style={{
            maxWidth: "900px",
            width: "100%",
          }}
        >
          <div className="flex--between">
            <Header>Products</Header>
            <Button basic onClick={() => setAddproduct(!addProduct)}>
              {addProduct ? "Hide" : "Add"}
            </Button>
          </div>

          <Divider></Divider>
          {addProduct && (
            <AddProduct
              country={order.shipping.shipTo.country}
              handleAddProduct={(item) => handleAddOrderProduct(item)}
              businessclient={order.businessclient._id}
              warehouse={
                order.shipping.shipFrom ? order.shipping.shipFrom._id : null
              }
            />
          )}
          <ProductsList
            orderId={match.params.id}
            items={order.items}
            handleDeleteItem={handleDeleteItem}
          />
        </Segment>

        <Segment
          style={{ maxWidth: "450px", width: "440px" }}
          name="payment"
          loading={updatingSegment == "payment"}
        >
          <Header as="h3">Payment</Header>
          <Divider />

          <table>
            <tr id="paymentStatus" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Status</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "payment.status" && (
                  <div>
                    <Input
                      value={updateField.value}
                      style={{ height: "25px" }}
                      onChange={(e, { value }) => {
                        setShowUpdateField({ ...updateField, value });
                      }}
                    ></Input>
                  </div>
                )}
                {updateField.name !== "payment.status" && order.payment.status}{" "}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "payment.status" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("payment")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "payment.status" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "payment.status",
                        value: order.payment.status,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>
            <tr id="paymentType" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Type</td>
              <td style={{ textAlign: "left" }}>{order.payment.type}</td>
            </tr>

            <tr id="codApply" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>COD apply</td>
              <td style={{ textAlign: "left", width: "150px" }}>
                <Checkbox
                  // toggle
                  name="codApply"
                  checked={order.payment.COD.apply}
                  onChange={(e, { checked }) => {
                    handleCheckCOD(checked);
                  }}
                />
              </td>
            </tr>

            {order.payment.COD.apply && (
              <tr id="codAmount" style={{ height: "33px" }}>
                <td style={{ width: "120px", fontWeight: "bold" }}>
                  COD amount
                </td>
                <td style={{ textAlign: "left" }}>
                  {updateField.name === "payment.COD.amount" && (
                    <div>
                      <Input
                        value={updateField.value}
                        style={{ height: "25px" }}
                        onChange={(e, { value }) => {
                          setShowUpdateField({ ...updateField, value });
                        }}
                      ></Input>
                    </div>
                  )}
                  {updateField.name !== "payment.COD.amount" &&
                    order.payment.COD.amount}{" "}
                  {order.payment.COD.amount != order.payment.COD.amountInit && (
                    <div>(Initial amount: {order.payment.COD.amountInit})</div>
                  )}
                </td>
                <td style={{ textAlign: "right", width: "150px" }}>
                  {updateField.name === "payment.COD.amount" && (
                    <div>
                      <Button
                        size="mini"
                        basic
                        color="red"
                        onClick={() => {
                          setShowUpdateField({ name: null });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="mini"
                        basic
                        color="green"
                        onClick={() => handleUpdateField("payment")}
                      >
                        Save
                      </Button>
                    </div>
                  )}

                  {updateField.name !== "payment.COD.amount" && (
                    <Button
                      icon="edit"
                      basic
                      size="mini"
                      onClick={() => {
                        setShowUpdateField({
                          name: "payment.COD.amount",
                          value: order.payment.COD.amount,
                        });
                      }}
                    ></Button>
                  )}
                </td>
              </tr>
            )}
          </table>
        </Segment>

        <Segment name="invoice" style={{ maxWidth: "450px", width: "440px" }}>
          <Header as="h3">Invoice</Header>
          <Divider />
          {!order.invoice && (
            <div>Invoicing service off. U can turn it on in settings.</div>
          )}
          {order.invoice && (
            <>
              <div>
                <Link to={`/invoices/${order.invoice._id}`} target="_blank">
                  Number:{" "}
                  {order.invoice.sequenceNumber
                    ? order.invoice.sequenceNumber
                    : "DRAFT"}
                </Link>
                {order.invoice.creditNote && (
                  <div>
                    CreditNote: {order.invoice.creditNote.sequenceNumber}
                  </div>
                )}
              </div>
              <p>Netto Total: {order.invoice.NETTO_total}</p>
              <p>VAT Total: {order.invoice.VAT_total}</p>
              <p>BRUTTO Total: {order.invoice.BRUTTO_total}</p>
            </>
          )}

          {order.print && order.print.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              <Header as="h3">For Print</Header>
              <Divider />

              {order.print.map((item, index) => (
                <li>
                  {item.name ? item.name : index} - <a href={item.url}>view</a>
                </li>
              ))}
            </div>
          )}
        </Segment>
        <Segment name="services" style={{ width: "440px" }}>
          {" "}
          <Services services={order.services}></Services>{" "}
        </Segment>

        <div
          name="messages"
          style={{ marginTop: "14px", width: "900px" }}
          loading={updatingSegment == "messsages"}
        >
          <Comments
            trigger={"Order"}
            triggerId={match.params.id}
          ></Comments>
        </div>

        <ChangeLogs changeLogs={order.changeLogs} />

        <Newrefund
          order={order}
          show={showAddRefund}
          closeModal={() => setRefundOpen(false)}
        ></Newrefund>
      </div>
    </div>
  );
};

export default Order;
